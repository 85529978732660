.theme-none {
  & .bg-color {
    @apply bg-transparent;
  }
  & .bg-colord {
    @apply bg-black;
  }
  & .stroke-color {
    @apply stroke-black;
  }

  & .txt-color, & .anchor-color a, & .hover-color:hover {
    @apply text-light-navy;
  }

  & .anchor-color a:after {
    @apply bg-light-navy;
  }

  & .main-menu {
    & .txt-color, & .anchor-color a, & .hover-color:hover {
      color: #84c7e7;
    }
  }
}

.theme-pink {
  & .bg-color {
    @apply bg-very-light-pink;
  }
  & .bg-colord {
    @apply bg-deep-pink;
  }
  & .stroke-color {
    @apply stroke-deep-pink;
  }

  & .inset-shadow {
    box-shadow: inset 0 1px 20px 20px rgba(195, 0, 102, 0.05);
  }

  & .txt-color, & .anchor-color a, & .hover-color:hover {
    @apply text-deep-pink;
  }

  & .anchor-color a:after {
    @apply bg-deep-pink;
  }

  & .main-menu {
    & .txt-color, & .anchor-color a, & .hover-color:hover {
      color: #d575a7;
    }
  }
}

.theme-orange {
  & .bg-color {
    @apply bg-light-tan;
  }
  & .bg-colord {
    @apply bg-reddish;
  }
  & .stroke-color {
    @apply stroke-reddish;
  }

  & .inset-shadow {
    box-shadow: inset 0 1px 20px 20px rgba(228, 109, 41, 0.05);
  }

  & .txt-color, & .anchor-color a, & .hover-color:hover {
    @apply text-reddish;
  }

  & .anchor-color a:after {
    @apply bg-reddish;
  }

  & .main-menu {
    & .txt-color, & .anchor-color a, & .hover-color:hover {
      color: #e6a082;
    }
  }
}

.theme-green {
  & .bg-color {
    @apply bg-light-grey;
  }
  & .bg-colord {
    @apply bg-slate-green;
  }
  & .stroke-color {
    @apply stroke-slate-green;
  }

  & .inset-shadow {
    box-shadow: inset 0 1px 20px 20px rgba(98, 131, 103, 0.05);
  }

  & .txt-color, & .anchor-color a, & .hover-color:hover {
    @apply text-slate-green;
  }

/*   & .stroke-current {
    stroke: #628367;
  } */

  & .anchor-color a:after {
    @apply bg-slate-green;
  }

  & .main-menu {
    & .txt-color, & .anchor-color a, & .hover-color:hover {
      color: #9dc9b7;
    }
  }
}

.theme-blue {
  & .bg-color {
    @apply bg-light-blue-grey;
  }
  & .bg-colord {
    @apply bg-water-blue;
  }
  & .stroke-color {
    @apply stroke-water-blue;
  }

  & .inset-shadow {
    box-shadow: inset 0 1px 20px 20px rgba(29, 153, 213, 0.05);
  }

  & .txt-color {
    @apply text-light-navy;
  }

  & .anchor-color a, & .hover-color:hover {
    @apply text-water-blue;
  }

  & .anchor-color a:after {
    @apply bg-water-blue;
  }

  & .main-menu {
    & .txt-color, & .anchor-color a, & .hover-color:hover {
      color: #84c7e7;
    }
  }
}

.theme-yellow {
  & .bg-color {
    @apply bg-pale;
  }
  & .bg-colord {
    @apply bg-russet;
  }
  & .stroke-color {
    @apply stroke-russet;
  }

  & .inset-shadow {
    box-shadow: inset 0 1px 20px 20px rgba(152, 58, 8, 0.05);
  }

  & .txt-color, & .anchor-color a, & .hover-color:hover {
    @apply text-russet;
  }

  & .anchor-color a:after {
    @apply bg-russet;
  }

  & .main-menu {
    & .txt-color, & .anchor-color a, & .hover-color:hover {
      color: #f4ca7c;
    }
  }
}

.theme-purple {
  & .bg-color {
    @apply bg-pale-grey;
  }
  & .bg-colord {
    @apply bg-light-eggplant;
  }
  & .stroke-color {
    @apply stroke-light-eggplant;
  }

  & .inset-shadow {
    box-shadow: inset 0 1px 20px 20px rgba(135, 78, 143, 0.05);
  }

  & .txt-color, & .anchor-color a, & .hover-color:hover {
    @apply text-light-eggplant;
  }

  & .anchor-color a:after {
    @apply bg-light-eggplant;
  }

  & .main-menu {
    & .txt-color, & .anchor-color a, & .hover-color:hover {
      color: #c192d6;
    }
  }
}

.theme-blueBaerekraft {
  & .bg-color {
    @apply bg-blue;
  }
  & .bg-colord {
    @apply bg-blue;
  }
  & .stroke-color {
    @apply stroke-black;
  }

  & .inset-shadow {
    box-shadow: inset 0 1px 20px 20px rgba(135, 78, 143, 0.05);
  }

  & .txt-color, & .anchor-color a, & .hover-color:hover {
    @apply text-black;
  }

  & .anchor-color a:after {
    @apply bg-black;
  }

  & .main-menu {
    & .txt-color, & .anchor-color a, & .hover-color:hover {
			@apply text-black;
    }
  }
}
.theme-pinkBaerekraft {
  & .bg-color {
    @apply bg-light-red;
  }
  & .bg-colord {
    @apply bg-light-red;
  }
  & .stroke-color {
    @apply stroke-black;
  }

  & .inset-shadow {
    box-shadow: inset 0 1px 20px 20px rgba(135, 78, 143, 0.05);
  }

  & .txt-color, & .anchor-color a, & .hover-color:hover {
    @apply text-black;
  }

  & .anchor-color a:after {
    @apply bg-black;
  }

  & .main-menu {
    & .txt-color, & .anchor-color a, & .hover-color:hover {
			@apply text-black;
    }
  }
}
.theme-yellowBaerekraft {
  & .bg-color {
    @apply bg-yellow;
  }
  & .bg-colord {
    @apply bg-yellow;
  }
  & .stroke-color {
    @apply stroke-black;
  }

  & .inset-shadow {
    box-shadow: inset 0 1px 20px 20px rgba(135, 78, 143, 0.05);
  }

  & .txt-color, & .anchor-color a, & .hover-color:hover {
    @apply text-black;
  }
  & .anchor-color a:after {
    @apply bg-black;
  }

  & .main-menu {
    & .txt-color, & .anchor-color a, & .hover-color:hover {
      @apply text-yellow;
    }
  }
}
.theme-purpleBaerekraft {
  & .bg-color {
    @apply bg-purple;
  }
  & .bg-colord {
    @apply bg-purple;
  }
  & .stroke-color {
    @apply stroke-black;
  }

  & .inset-shadow {
    box-shadow: inset 0 1px 20px 20px rgba(135, 78, 143, 0.05);
  }

  & .txt-color, & .anchor-color a, & .hover-color:hover {
    @apply text-black;
  }

  & .anchor-color a:after {
    @apply bg-black;
  }

  & .main-menu {
    & .txt-color, & .anchor-color a, & .hover-color:hover {
      @apply text-black;
    }
  }
}
.theme-greenBaerekraft {
  & .bg-color {
    @apply bg-pale-green;
  }
  & .bg-colord {
    @apply bg-pale-green;
  }
  & .stroke-color {
    @apply stroke-black;
  }

  & .inset-shadow {
    box-shadow: inset 0 1px 20px 20px rgba(135, 78, 143, 0.05);
  }

  & .txt-color, & .anchor-color a, & .hover-color:hover {
    @apply text-black;
  }

  & .anchor-color a:after {
    @apply bg-black;
  }

  & .main-menu {
    & .txt-color, & .anchor-color a, & .hover-color:hover {
      @apply text-black;
    }
  }
}
.theme-greyBaerekraft {
  & .bg-color {
    @apply bg-grey;
  }
  & .bg-colord {
    @apply bg-grey;
  }
  & .stroke-color {
    @apply stroke-black;
  }

  & .inset-shadow {
    box-shadow: inset 0 1px 20px 20px rgba(135, 78, 143, 0.05);
  }

  & .txt-color, & .anchor-color a, & .hover-color:hover {
    @apply text-black;
  }

  & .anchor-color a:after {
    @apply bg-black;
  }

  & .main-menu {
    & .txt-color, & .anchor-color a, & .hover-color:hover {
      @apply text-black;
    }
  }
}
