.cat-wrapper {
  & .cat-active {
    flex: 1;
  }

  & .cat {
    @apply w-full h-16 px-6 flex items-center;
  }

  & .cats {
    left: -2px;
    right: calc(4rem - 2px);
    top: 100%;
    margin-top: 2px;

    & .cat {
      @apply border-b border-black;
      transition: background-color 300ms ease-out-cubic;

      &:hover {
        @apply bg-light-grey;
      }

      &:last-child {
        border: none;
      }
    }
  }
}

.theme-yellowBaerekraft {
  & .article-teaser,
  & .workshop-teaser {
    &:hover {
      & .article-title {
        @apply text-black;
      }
    }
  }
}
.article-teaser,
.workshop-teaser {
  @apply relative;

  &:after {
    @apply bg-black absolute;
    content: "";
    left: 1rem;
    right: 1rem;
    bottom: 0;
    height: 2px;
  }
  & .cat-link {
    transition: color 300ms ease-out-cubic;
  }

  & .image-wrapper {
    overflow: hidden;
    & svg {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    & img {
      transition: transform 600ms ease-out-cubic;
    }
  }

  & .article-title,
  & .workshop-title {
    transition: color 300ms ease-out-cubic;
  }

  & .article-link,
  & .workshop-link {
    text-indent: -9999px;
  }

  &:hover {
    & .image-wrapper {
      & img {
        transform: translateX(100%);
      }
    }

    & .article-title {
      @apply text-light-eggplant;
    }

    /* & .workshop-title {
      @apply text-slate-green;
    } */
  }
}

.activity-teaser {
  /* @apply px-3 pb-3; */

  & .card {
    @apply p-8 relative flex-1;
    min-height: 300px;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    transition: transform 600ms ease-out-cubic;
  }

  & .card-link {
    @apply absolute pin-l pin-t w-full h-full z-10 overflow-hidden;
    text-indent: -9999px;
  }

  & .card-bg {
    @apply absolute w-full h-full pin-l pin-t;

    & .bg-img {
      @apply absolute pin-r pin-b object-contain;
      width: 70%;
      height: 70%;
      object-position: bottom right;
    }
  }

  & .card-content {
    @apply relative;
    width: 100%;
    z-index: 1;
  }

  & .activity-list {
    width: 66%;
  }

  & .svg {
    opacity: 0;
    top: 0%;
    left: 0%;
    transition: opacity 600ms ease-out-cubic;
  }

  &.slide:hover {
    & a {
      overflow: hidden;
    }

    & .card {
      transform: translateX(100%);
    }

    & .svg {
      opacity: 1;
    }
  }
}

.entry-workshop,
.entry-activities {
  & .workshop-teaser {
    &:after {
      content: none;
    }
  }
}
