.article-filter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;

  & .article-item {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-right: 0.5rem;
    cursor: pointer;
    position: relative;
    transition: all 300ms ease-in-out;

    &:last-child {
      margin-right: 0;
    }

    & .radio-button-filter {
      height: 1.25rem;
      width: 1.25rem;
      border-radius: 100%;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;

      @apply border border-water-blue;

      & .radio-button-dot {
        height: 1.25rem;
        width: 1.25rem;
        border-radius: 100%;
        scale: 0;
        transition: all 300ms ease-in-out;

        @apply bg-water-blue;
      }
    }

    &:hover {
      & .radio-button-dot {
        scale: 0.5;
        transition: all 300ms ease-in-out;
      }
    }

    &.active {
      & .radio-button-dot {
        scale: 1;
        transition: all 200ms ease-in-out;
      }
    }
  }
}

.article-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 2rem;

  @media (max-width: 640px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  & .article-item {
    @apply relative;

    & .cat-link {
      transition: color 300ms ease-out-cubic;
    }

    & .image-wrapper {
      overflow: hidden;
      line-height: 0;

      & svg {
        bottom: 5%;
        left: 15%;
        transform: translateY(-50%) translateX(-50%);
      }

      & img {
        transition: transform 600ms ease-out-cubic;
      }
    }

    &:hover {
      & .image-wrapper {
        & img {
          transform: translateX(30%);
        }
      }
    }
  }
}

.plan-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 2rem;

  @media (max-width: 640px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  & .article-item {
    @apply relative;

    & .cat-link {
      transition: color 300ms ease-out-cubic;
    }

    & .content-wrapper {
      overflow: hidden;
      line-height: 0;
      transition: transform 600ms ease-out-cubic;
      z-index: 10;

      @apply bg-pale-green;
    }

    & .card-link {
      width: 100%;
      height: 18.75rem;
      overflow: hidden;

      @apply bg-light-red;

      & h2 {
        @apply text-black;
      }

      & svg {
        bottom: 5%;
        left: 15%;
        transform: translateY(-50%) translateX(-50%);
      }
    }

    &:hover {
      & .content-wrapper {
        transform: translateX(30%);
      }
    }
  }
}

.article-header-inner-title {
  max-width: max-content;
}
