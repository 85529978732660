.menu-button {
  outline: 1px solid tranparent;
  & .menu-button-lines {
    width: 41.66667%;
    height: 25%;

    & .line {
      @apply bg-white absolute pin-r;
      transform: translateY(-50%);
      height: 2px;
      transition: all 300ms ease-out-cubic;
      will-change: transform, left, top, width;

      &.line-1 {
        top: 0;
      }

      &.line-2 {
        top: 50%;
        width: 60%;
      }

      &.line-3 {
        top: 100%;
        width: 72.5%;
      }
    }
  }

  &:before {
    content: '';
    @apply w-full h-full rounded-full bg-black absolute border-2 border-black;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 300ms ease-out-cubic;
    will-change: transform;
  }

  &:hover {
    &:before {
      transform: translate(-50%, -50%) scale(1.2);
    }

    & .line {
      &.line-1 {
        top: 30%;
        width: 40%;
        right: 60%;
        transform: translateY(-50%) rotate(-40deg);
      }

      &.line-2 {
        width: 100%;
      }

      &.line-3 {
        top: 70%;
        width: 40%;
        right: 60%;
        transform: translateY(-50%) rotate(40deg);
      }
    }
  }

  &.open {
    & .line {
      &.line-1 {
        top: 50%;
        width: 100%;
        right: 0%;
        transform: translateY(-50%) rotate(-45deg);
      }

      &.line-2 {
        opacity: 0;
      }

      &.line-3 {
        top: 50%;
        width: 100%;
        right: 0%;
        transform: translateY(-50%) rotate(45deg);
      }
    }

    &:before {
      @apply border-white;
    }

    &:hover {
      & .line {
        &.line-1 {
          top: 30%;
          width: 40%;
          right: 0%;
          transform: translateY(-50%) rotate(40deg);
        }

        &.line-2 {
          width: 100%;
          opacity: 1;
        }

        &.line-3 {
          top: 70%;
          width: 40%;
          right: 0%;
          transform: translateY(-50%) rotate(-40deg);
        }
      }
    }
  }
}