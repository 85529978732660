.entry-workshop, .entry-activities {
	& .banner-image {
		max-width: 300px;

		& img {
			vertical-align: bottom;
		}
	}

	& .back-link {

	    & svg {
	    	transform: rotate(180deg);
	    }
	}
}

.entry-activities {

	& .clock-icon {
		width: 20px;
		height: 20px;
	}
}