.pager {
  & .pager-link-num {
    @apply opacity-50 text-black inline-flex relative justify-center items-center;
    width: 40px;
    height: 30px;
    transition: opacity 300ms ease-out-cubic;

    &:hover {
      @apply opacity-100;
    }
  }

  & .elipsis {
    @apply opacity-50 text-black inline-flex relative justify-center items-center;
    height: 30px;
  }

  & .current {
    @apply relative inline-flex relative justify-center items-center;
    width: 40px;
    height: 30px;
    perspective: 100px;

    &:before {
      @apply absolute bg-pale-grey;

      z-index: -1;
      width: 30px;
      height: 30px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotateY(10deg);
      content: '';
    }
  }
}