/**
 * components/webfonts.css
 * 
 * Project webfonts.
 *
 */
@font-face {
  font-family: raisonne;
  src: url('../../assets/fonts/raisonne-regular-pro.eot');
  src: url('../../assets/fonts/raisonne-regular-pro.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/raisonne-regular-pro.woff2') format('woff2'), 
    url('../../assets/fonts/raisonne-regular-pro.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: raisonne;
  src: url('../../assets/fonts/raisonne-oblique-pro.eot');
  src: url('../../assets/fonts/raisonne-oblique-pro.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/raisonne-oblique-pro.woff2') format('woff2'), 
    url('../../assets/fonts/raisonne-oblique-pro.woff') format('woff');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: raisonne;
  src: url('../../assets/fonts/raisonne-demibold-pro.eot');
  src: url('../../assets/fonts/raisonne-demibold-pro.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/raisonne-demibold-pro.woff2') format('woff2'), 
    url('../../assets/fonts/raisonne-demibold-pro.woff') format('woff');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: raisonne;
  src: url('../../assets/fonts/raisonne-demibold-oblique-pro.eot');
  src: url('../../assets/fonts/raisonne-demibold-oblique-pro.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/raisonne-demibold-oblique-pro.woff2') format('woff2'), 
    url('../../assets/fonts/raisonne-demibold-oblique-pro.woff') format('woff');
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: raisonne;
  src: url('../../assets/fonts/raisonne-light-pro.eot');
  src: url('../../assets/fonts/raisonne-light-pro.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/raisonne-light-pro.woff2') format('woff2'), 
    url('../../assets/fonts/raisonne-light-pro.woff') format('woff');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: raisonne;
  src: url('../../assets/fonts/raisonne-light-oblique-pro.eot');
  src: url('../../assets/fonts/raisonne-light-oblique-pro.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/raisonne-light-oblique-pro.woff2') format('woff2'), 
    url('../../assets/fonts/raisonne-light-oblique-pro.woff') format('woff');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: apercu;
  src: url('../../assets/fonts/apercu.ttf');
  font-style: normal;
  font-weight: 400;
}