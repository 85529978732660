.article-header,
.article-body {
  transform: translateZ(1px);
}

.entry-article {
  & .cat-wrapper {
    @apply min-w-xs;
  }

  & .back-link {
    right: 100%;

    & svg {
      transform: rotate(180deg);
    }
  }
}

.accordion-container {
  width: 100%;
  cursor: pointer;

  & .accordion-box {
    position: relative;
    border-bottom: 1px solid black;
    padding: 1.7188rem 0;

    @screen md {
      padding: 1.7188rem 1.5625rem;
    }

    &:hover {
      & .accordion-title {
        transition: all 0.3s ease-in-out;
        @apply text-light-eggplant;
      }
    }
  }

  &:first-child {
    border-top: 1px solid black;
  }

  & .accordion-title {
    position: relative;
    color: black;
    cursor: pointer;
    padding: 0;
    font-size: 1.125rem;
    transition: all 0.3s ease-in-out;
  }

  & .accordion-content {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    overflow-y: auto;
    opacity: 0;
  }

  & .accordion-box.active .accordion-content {
    height: auto;
    padding-top: 1.3563rem;
    opacity: 1;

    @screen md {
      padding-top: 2.3563rem;
    }
  }

  & .accordion-box .accordion-icon {
    transition: all 0.3s ease-in-out;
  }

  & .accordion-box.active .accordion-icon {
    transition: all 0.3s ease-in-out;
    transform: rotate(45deg);
  }

  & .accordion-content p {
    font-size: 1.125rem;
    width: 100%;
    max-width: 37.375rem;
    line-height: 2.125rem;
  }
}
