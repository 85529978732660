/**
 * components/global.css
 *
 * Project-wide styles
 *
 */
body {
  @apply font-raisonne bg-beige-10;
  scroll-behavior: smooth;

  & input:focus {
    outline: none;
  }

  & textarea:focus {
    outline: none;
  }

  & button:focus {
    outline: none;
  }

  & a {
    @apply no-underline;

    &.simple-anim {

      &:hover {
        @apply underline;
      }
    }
  }

  & .anim-links {
    & a {
      @apply relative inline underline;
      transition: filter 300ms ease-out-cubic;
      filter: brightness(1);
      

      &:hover {
        filter: brightness(1.6);
      }
    }
  }
}

.links {
  & .link {
    & svg {
      transition: transform 300ms ease-out-cubic;
    }

    &:hover {
      & svg {
        transform: translate(50%, 0);
      }
    }
  }
}

.download-link {
  & svg g#arrow {
    transition: transform 300ms ease-out-cubic;
  }

  &:hover {
    & svg g#arrow {
      transform: translate(5px, 4px);
    }
  }
}

a.download-link {
  color: #000000 !important;
}

.sign-language-link {
  cursor: pointer;

  & circle {
    transition: transform 300ms ease-out-cubic;
  }

  & g#hand-upper {
    transition: transform 300ms ease-out-cubic;
  }

  & g#hand-lower {
    transition: transform 300ms ease-out-cubic;
  }

  &:hover {
    & circle {
      transform: scale(.9, .9);
    }
    
    & g#hand-upper {
      transform: rotate(-25deg);
    }

    & g#hand-lower {
      transform: rotate(25deg);
    }

    & .sign-language-label {
      opacity: 1;
    }
  }
}

.sign-language-label {
  opacity: 0;
  transition: opacity 300ms ease-out-cubic;
}

.sign-language-icon {
  width: 40px;
  /* height: 20px; */

  & svg {
    display: block;
    width: inherit;

    & circle {
      transform-origin: 50% 50%;
    }

    & #hand-upper {
      transform-origin: 50% 50%;
    }

    & #hand-lower {
      transform-origin: 50% 50%;
    }
  }
}

section.sign-language-video {
  height: 0px;
}

.tilted-label {
    display: inline-block;
    padding: .25rem .75rem;
    position: relative;
    margin-bottom: 1rem;
    transform-style: flat;
    perspective: 100px;

    & .tilted-label-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      transform: rotateY(10deg);
      transform-style: flat;
      outline: 1px solid transparent;
    }

    & .tilted-label-text {
      transform: translateZ(6px);
    }
}

.main {
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}

@responsive {
  .border-box {
    box-sizing: border-box;
  }
  .content-box {
    box-sizing: content-box;
  }
}