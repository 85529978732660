.answer-button {
	@apply bg-pale-grey;
	&.selected {
		@apply bg-pale;
	}
	&.correct {
		@apply bg-light-grey;
	}
	&.incorrect {
		@apply bg-very-light-pink;
	}
}
