.workshop-body {
  /* & section:nth-child(odd) {
    @apply bg-light-grey;
  } */
  
  & .text h2 {
    @apply text-xl font-semibold;
    margin-bottom: 1.5rem;
  }

  & .text h3 {
    @apply text-xl font-semibold;
    margin-bottom: 1.5rem;
  }
}


@screen sm {
  .workshop-body {
    & .text h3 {
      @apply text-2xl;
    }
  }
}