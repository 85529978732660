.text-style-huge {
  @apply text-3xl leading-tight font-semibold;
}
.text-style-large {
  @apply text-3xl leading-tight;
}
.text-style-bigger {
  @apply text-3xl leading-tight font-semibold;
}
.text-style-big {
  @apply text-2xl leading-tight font-semibold;
}
.text-style-big2 {
  @apply text-xl font-semibold;
  line-height: 1.3;
}
.text-style-big3 {
  @apply text-lg font-apercu leading-normal;
}
.text-style-normalb {
  @apply text-lg font-semibold leading-normal;
}
.text-style-normal {
  @apply text-lg leading-normal;
}
.text-style-smallb {
  @apply text-base font-semibold leading-normal;
}
.text-style-small {
  @apply text-base leading-normal;
}
.text-style-smallerb {
  @apply text-sm leading-normal font-semibold;
}
.text-style-smaller {
  @apply text-sm italic leading-normal;
}
.text-style-medium {
  @apply text-lg2 font-semibold leading-normal;
}

@screen sm {
  .text-style-huge {
    @apply text-5xl;
  }
  .text-style-large {
    @apply text-6xl;
  }
  .text-style-bigger {
    @apply text-5xl;
  }
  .text-style-big {
    @apply text-3xl;
  }
  .text-style-big2 {
    @apply text-2xl;
  }
  .text-style-big3 {
    @apply text-xl;
  }
}

p {
  margin-bottom: 1.5em;
}

ol {
  @apply pl-8 my-8;
  list-style: none;
  counter-reset: custom-counter;
  /* list-style-position: inside; */

  & li {
    @apply mb-4;
    counter-increment: custom-counter;
    list-style-position: inside;
    text-indent: -1rem;

    &:before {
      @apply font-semibold inline-block;
      min-width: 1rem;
      margin-top: -2px;
      content: counter(custom-counter) ".";
    }

    & ol {
      @apply pl-8 my-4;
      list-style-type: lower-alpha;

      & li {
        text-indent: -2rem;

        &:before {
          content: "";
          min-width: 1rem;
        }

        & ol {
          @apply pl-12;
          list-style-type: lower-roman;

          & li {
            text-indent: -1rem;

            &:before {
              content: "";
              min-width: 0.5rem;
            }
          }
        }
      }
    }
  }

  & li:last-child {
    /* display: block; */
  }
}

ul {
  @apply pl-0 my-8;
  list-style: none;

  & li {
    @apply mb-4 flex;
    /* padding-left: 3rem;
		text-indent: -2.6rem; */

    &:before {
      @apply inline-block bg-black rounded-full mr-8;
      min-width: 10px;
      height: 10px;
      margin-top: 6px;
      content: "";
    }
  }
}

ul.list-none {
  @apply pl-0 my-0 mb-6;

  & li {
    @apply mb-2 flex;

    &:before {
      @apply inline-block bg-transparent mr-0;
      min-width: 0px;
      height: 0px;
      margin-top: 0px;
      content: "";
    }
  }
}

.text {
  & h2 {
    @apply text-3xl leading-tight font-semibold;
  }

  & h3 {
    @apply text-3xl leading-tight my-12;
  }

  & h4 {
    @apply text-2xl leading-tight font-semibold my-8;
  }

  & h5 {
    @apply text-xl font-semibold my-4;
  }

  @screen sm {
    & h2 {
      @apply text-5xl;
    }
  }
}
