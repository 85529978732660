.cards {
  @apply px-3 pb-3;

  & .card {
    @apply p-8 relative flex-1 my-3;
    min-height: 300px;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  & .card-link {
    @apply absolute pin-l pin-t w-full h-full z-10 overflow-hidden;
    text-indent: -9999px;
  }

  & .card-bg {
    @apply absolute w-full h-full pin-l pin-t;

    & .bg-img {
      @apply absolute pin-r pin-b object-contain;
      width: 70%;
      height: 70%;
      object-position: bottom right;
    }
  }

  & .card-content {
    @apply relative;
    width: 100%;
    z-index: 1;
  }

  &.standalone {
    gap: 1rem;
    height: auto;
    flex-wrap: nowrap;
    flex-direction: column;

    & .card {
      margin: 0 !important;
      width: auto !important;
      min-height: 23rem;
      & .card-content {
        width: 391px;
        max-width: 90%;
      }
    }

    @screen lg {
      flex-direction: row;
      height: 25rem;
    }
  }
}

.articles {
  & .row {
    margin-bottom: 1rem;
  }

  /* & .row .card {
    margin-left: .5rem;
    margin-right: .5rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  } */
}

.activities {
  & .row .activity-teaser {
    padding-left: 0;
    padding-right: 0;

    /*     &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    } */
  }
}

@screen sm {
  .cards {
    @apply pb-3 flex flex-wrap;

    & .card {
      @apply m-3;
    }

    & .card-content {
      transform: translateZ(10px);
      width: 310px;
      max-width: 90%;
    }
  }

  .activities {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2em;

    /* & .row .activity-teaser {
      padding-left: .5rem;
      padding-right: .5rem;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    } */
  }
}

@screen md {
  .cards {
    & .card {
      width: 50%;
    }
  }
}

@screen xl {
  .activities {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2em;
  }

  .cards {
    @apply px-0 pb-0;
    display: grid;
    grid-template-columns: 20px 30vw 20px auto 20px 30vw 20px;
    grid-template-rows: 20px 40% 20px auto 20px 40% 20px;

    height: 94vh;

    & .card {
      @apply m-0;
      min-height: 0;
      width: auto;
    }

    & .card-1 {
      grid-column-start: 2;
      grid-column-end: span 1;
      grid-row-start: 2;
      grid-row-end: span 3;
    }
    & .card-2 {
      grid-column-start: 4;
      grid-column-end: span 1;
      grid-row-start: 2;
      grid-row-end: span 3;
    }
    & .card-4 {
      grid-column-start: 2;
      grid-column-end: span 1;
      grid-row-start: 6;
      grid-row-end: span 1;
    }
    & .card-5 {
      grid-column-start: 4;
      grid-column-end: span 1;
      grid-row-start: 6;
      grid-row-end: span 1;
    }
    & .card-3 {
      grid-column-start: 6;
      grid-column-end: span 1;
      grid-row-start: 2;
      grid-row-end: span 1;
    }
    & .card-6 {
      grid-column-start: 6;
      grid-column-end: span 1;
      grid-row-start: 4;
      grid-row-end: span 3;
    }
  }
}
