@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
   .banner-text-wrapper {
     position: absolute;
     top: 50%;
     transform: translateY(-50%);
   }

   @screen xl {
    .cards {
      grid-template-columns: 20px 30vw 20px 1fr 20px 30vw 20px;
      grid-template-rows: 20px 40% 20px 1fr 20px 40% 20px;
    }
   }
}