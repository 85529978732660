.button,
.long-button {
  min-width: 160px;
  box-shadow: 5px 5px 0 0 black;
  @apply inline-flex items-center border-2 border-black text-style-smallb text-center px-8 py-2 bg-white cursor-pointer text-black text-center justify-center;
  will-change: transform;

  &.icon {
    @apply pr-3;
  }

  & svg {
    width: 20px;
    margin-left: 0.5em;
  }

  &.top-button {
    min-width: 100px;
    @apply text-style-smallerb;

    @screen md {
      min-width: 120px;
      @apply text-style-smallb;
    }
  }

  &.no-border {
    @apply border-none shadow-none pb-1;
  }
}
