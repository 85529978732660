/**
 * pages/homepage.pcss
 *
 * Styles for the Home page.
 *
 */
.entry-home {
  & h1 {
    @apply text-4xl;
  }

  & footer {
    border-top: none;
  }
}