@import './menuButton.pcss';

.top-link {
  @apply text-black;
  transition: color 300ms ease-out-cubic;
}

#topbar {
  transition: all 600ms ease-out-cubic;

  &.lock-anim {
    transition: transform 600ms ease-out-cubic;
  }

  &:before {
    @apply pin-l pin-t absolute w-full h-full bg-beige-10;
    content: '';
    transition: transform 600ms ease-out-cubic;
    transform: translate(0, -100%);
    z-index: -1;
  }

  &.add-bg {
    @apply h-16;
    &:before {
      transform: translate(0, 0%);
    }
  }
}

.menu-button-wrapper {
  transition: all 600ms ease-out-cubic;

  &.lock-anim {
    transition: transform 600ms ease-out-cubic;
  }

  &.add-bg {
    @apply h-16;
    &:before {
      transform: translate(0, 0%);
    }
  }

  &.open {
    @apply h-24;
    transform: none;
  }
}

.lnu-logo {
  @apply pr-16;
  line-height: 0;
}

@screen sm {
  .menu-button-wrapper {
    &.open {
      @apply h-32;
    }
  }

  .lnu-logo {
    @apply pr-0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}