.element-text {
  & ul {
    & li {
      @apply block;
  		padding-left: 3rem;
  		text-indent: -2.6rem;
    }
  }
}

.element-cta {
  & .cta-arrow, & .cta-text {
    transform: translateZ(6px);
  }

  & .cta-arrow {
    @apply w-8 mt-8;
  }

  & .cta-bg {
    z-index: -1;
  }
}

.article-nav {
  & a {
    & span {
      transition: color 300ms ease-out-cubic;
    }

    & svg path {
      @apply stroke-black;
      transition: stroke 300ms ease-out-cubic;
    }

    &:hover {
      & span {
        @apply text-light-eggplant;
      }

      & svg path {
        @apply stroke-light-eggplant;
      }
    }
  }
}

.element-video {
  & .video-wrapper {
    padding-top: 56.25%;
  }
}

.element-quote {
  &:after {
    content: '“';
    @apply text-5xl italic text-light-eggplant absolute pin-l font-raisonne;
    top: -1rem;
  }
}

@screen sm {
  .element-cta {
    & .cta-arrow {
      @apply mr-8 absolute mt-0;
      right: 0;
      top: 50%;
      transform: translate(0, -50%) translateZ(6px);
    }
  }
}
