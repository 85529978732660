.test {
  color: white;
}

.main-banner {
  position: relative;

  & .bg-image-wrapper {
    & .bg-image {
      width: 100%;
      height: auto;
    }
  }

  &.type-long,
  &.type-articles {
    & .bg-image-wrapper {
      position: absolute;
      width: auto;
      left: 10px;
      top: 10px;
      bottom: 10px;
      right: 10px;
      transform: none;
      border-radius: 13px;
      overflow: hidden;

      & .bg-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &.type-long {
    & .bg-image-wrapper {
      @apply flex items-end;

      & .bg-image {
        object-position: right;
        height: 60%;
      }
    }
  }

  &.type-articles {
    & .bg-image-wrapper {
      @apply flex items-center justify-end;

      & .bg-image {
        @apply w-1/2 h-auto object-contain mr-8;
      }
    }
  }

  & .down-arrow {
    @apply cursor-pointer;
    top: calc(100% + 3em);
  }

  & .banner-ingress {
    margin-bottom: 1rem;
  }
}

.entry-longPage {
  & .main-banner .down-arrow {
    top: auto;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

@screen lg {
  .main-banner {
    & .bg-image-wrapper {
      position: absolute;
      right: 0%;
      top: 50%;
      transform: translate(0, -50%);
      z-index: -1;
      width: 54%;

      & .bg-image {
        width: 100%;
        height: auto;
      }
    }

    & .banner-ingress {
      max-width: 370px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1rem;
    }

    &.type-long,
    &.type-articles {
      & .bg-image-wrapper {
        left: 20px;
        top: 20px;
        bottom: 20px;
        right: 20px;
      }
    }

    &.type-long {
      & .bg-image-wrapper {
        & .bg-image {
          object-position: center;
          height: 100%;
        }
      }
    }
  }
}
