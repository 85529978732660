@media print {
  .top-menu, .menu-button-wrapper, .cat-wrapper, .banner-image .absolute, .down-arrow, .more-text, .step .step-text.fade .step-short-text:after {
    display: none!important;
  }

  .lnu-logo {
    @apply px-0 relative;
    left: auto;
    top: auto;
    transform: none;
  }

  #main {
    @apply h-auto;
  }

  .article-body>div, .article-body>a, [style="opacity: 0; transform: matrix(1, 0, 0, 1, 0, 100);"], .step-image-wrapper {
    opacity: 1!important;
    transform: translate(0,0)!important;
  }

  [style="opacity: 0; height: 0px;"] {
    opacity: 1!important;
    height: auto!important;
  }

  #topbar {
    position: absolute;
  }

  .step .step-count {
    @apply text-black px-0;
  }

  .text-style-normal {
    font-size: 16px;
    line-height: 1.5;
  }
}