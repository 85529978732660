/* Custom radio and checkboxes */
[type="checkbox"] 
{
    position: absolute;
    left: -9999px;
}

[type="checkbox"] + label
{
    position: relative;
    padding-left: 2rem;
    cursor: pointer;
    line-height: 1;
    display: inline-block;
}

[type="checkbox"] + label:before
{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    background: transparent;
    border: 1.5px solid #000000;
    border-radius: 2px;
}


[type="checkbox"]:checked + label:before
{
  background: #000000;
}

[type="checkbox"]:checked + label:after,
[type="checkbox"]:not(:checked) + label:after {
 /*  	content: url('https://d2qzfxgyjuj01p.cloudfront.net/static-assets/icons/check-ok.svg');
    width: 10px;
    height: auto;
    position: absolute;
    top: 0px;
    left: 4px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease; */
}


[type="checkbox"]:not(:checked) + label:after
{
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="checkbox"]:checked + label:after
{
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}