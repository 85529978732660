.main-menu {
  min-width: 0;
  & .main-menu-item-sub {
    line-height: 35px;
  }

  & a {
    transition: color 300ms ease-out-cubic;
  }
}

@screen sm {
  .main-menu {
    min-width: 580px;
  }
}

.theme-none {
  & .main-menu {
    & a {      
      &:hover {
        @apply text-light-navy;
      }
    }
  }
}