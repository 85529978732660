.entry-coursesAndLearning, .entry-activities, .entry-courses {
	& .close-buttons {
		position: absolute;
		top: 90px;
		z-index: 200;
		width: 100%;
		padding: 0 20px;
	}

	& .close-button {
		position: absolute;
		top: 0px;
		right: 20px;
	}

	& .back-link {
		display: none;

		& svg {
			transform: rotate(180deg);
		}
	}

	& .stroke-black {
		& .stroke-current {
			stroke: #000000;
		}
	}

	& .filter-online-course {
		& .close-button {
			top: 90px;
		}
	}

	/* & .button-row {
		position: absolute;
		bottom: 40px;
	} */
}

#filterOverlay {
	/* height: 0; */
	opacity: 0;
	position: fixed;
	top: 0;
	overflow-y: auto;

	& [data-filter-category] {
		position: absolute;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100%;
		background: #FFFFFF;
		flex-direction: column;
		padding: 10rem 3rem 0 20px;
	}
}

/* #filterOverlay.debug {
	display: block;
	opacity: 1;
	height: 100vh;
} */

.clock-icon {
	width: 14px;
	height: 14px;
	& svg {
		width: 100%;
		height: auto;
		display: block;
	}
}

.filter-buttons {
	& .filter-button {
		& > .category-link {
			border-top: solid 2px #000000;
			border-bottom: none;
		}

		& > .category-link ~ .category-link {
			border-top: none;
			border-bottom: solid 2px #000000;
		}
	}

	& .filter-button:last-of-type {
		& .category-link {
			border-bottom: solid 2px #000000;
		}
	}

	& .filter-button.active {

		& a {
			background-color: #000000;
			color: #FFFFFF;
			pointer-events: none;

			/* &:before, &:after {
				content: none;
			} */

			& span {
				& svg {
					& path {
						transform: translateY(0px);
					}
				}
			}
		}
	}

	& a {
		color: #000;
		background: #FFF;

		overflow: hidden;
		position: relative;
		display: block;

		&:before {
			content: ''; /* attr(data-text); */
		    position: absolute;
		    width: 100%;
		    height: 0%;
		    bottom: 0;
		    left: 0;
		    background: #000000;
		    color: #FFF;
		    /* color: #000; */
		    overflow: hidden;
		    transition: all 0.6s;
		    /* transition: transform 0.3s; */
		    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
		}

		/* &:before, &:after {
			content: attr(data-text);
		    position: absolute;
		    width: 100%;
		    height: 50%;
		    left: 0;
		    background: #FFFFFF;
		    color: #000;
		    overflow: hidden;
		    transition: transform 0.3s;
		    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
		}

		&:before {
			top: 0;
		    padding-top: 1.4em;
		}

		&:after {
			bottom: 0;
    		line-height: 0;
		} */

		&:hover {
			color: #FFFFFF;
			&:before {
				/* transform: translate3d(0, -100%, 0); */
				height: 105%;
			}
			/* &:after {
				transform: translate3d(0, 100%, 0);
			} */
		}

		& span {
			& svg {
				& path {
					transform: translateY(-5px);
					transition: stroke 500ms ease-out-cubic, transform 500ms ease-out-cubic;
				}
			}
		}
	}

	& a:hover {
	/* 	background: #000000;
		color: #FFFFFF; */

		& span {
			& svg {
				& path {
					stroke: #FFF;
					transform: translateY(0px);
				}
			}
		}
	}
}


@screen sm {
	#filterOverlay {
		position: absolute;
		top: auto;
	}

	.filter-buttons {
		& .filter-button {
			& > .category-link {
				border-bottom: solid 2px #000000;
			}
		}
	}

	.filter-button {
		& > .category-link {
			border-left: solid 2px #000000;
			border-right: none;
		}

		& > .category-link ~ .category-link {
			border-left: none;
			border-top: solid 2px #000000;
			border-right: solid 2px #000000;
		}
	}

	.filter-button:last-of-type {
		& .category-link {
			border-right: solid 2px #000000;
		}
	}

	.filter-button.active {
		& .category-link {
			border-left: none;
			border-right: none;
		}

		& + .filter-button {
			& .category-link {
				border-left: none;
			}
		}
	}

	.entry-coursesAndLearning, .entry-activities, .entry-courses {
		& .close-buttons {
			top: 0;
		}

		& .close-button {
			top: 90px;
		}

		& .filter-online-course {
			& .close-button {
				top: 20px;
			}
		}

		/* & .button-row {
			position: relative;
			bottom: auto;
		} */
	}
}
