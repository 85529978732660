.entry-longPage {
  
}

@media only screen and (max-width: 320px) {
  .entry-longPage {
    & .bg-image {
      @apply hidden;
    }
  }
}

@media only screen and (max-width: 480px) and (max-height: 700px) {
  .entry-longPage {
    & .bg-image {
      @apply hidden;
    }
  }
}