.step {
  scroll-snap-align: start; 
  
  &.first, &.last {
    scroll-snap-align: none;
  }

  & .step-count {
    @apply text-white inline-block px-3 py-1 relative mb-4;
    transform-style: flat;
    perspective: 100px;

    & .step-count-bg {
      @apply w-full h-full absolute pin-l pin-t;
      transform: rotateY(10deg);
      transform-style: flat;
      outline: 1px solid transparent;
    }

    & .step-count-text {
      transform: translateZ(6px);
    }
  }

  &.odd {
    & .step-count {
      & .step-count-bg {
        transform: rotateY(-10deg);
      }
    }
  }

  &:last-child {
    @apply mb-0;
  }

  & .step-text {
    @apply relative overflow-hidden;

    &.fade {
      & .step-short-text {
        &:after {
          content: '';
          @apply pin-l pin-b w-full h-20 absolute;
          background-image: linear-gradient(to bottom, rgba(254,254,253, 0), #fefefd);
          transition: transform 300ms ease-out-cubic;
        }
      }
      

      &.no-fade {
        & .step-short-text {
          &:after {
            transform: translateY(100%);
          }
        }
      }
    }
  }

  & .more-text {
    @apply inline-flex items-center;

    & svg {
      margin-left: 1em;
      width: 14px;
      transition: transform 300ms ease-out-cubic;
    }

    &.open {
      & svg {
        transform: rotate(-180deg);
      }
    }
  }
}